<template>
  <div>
    <div v-if="isHidden" class="row">
      <div class="col-12">
        <div class="card card-border-color card-border-color-success">
          <div class="card-header card-header-divider">
            Add App
            <div
              class="btn btn-primary float-right"
              @click="isHidden = !isHidden"
            >
              Cancel
            </div>
          </div>
          <div class="card-body">
            <APKCreationForm />
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isHidden" class="row">
      <div class="col-sm-12">
        <div class="card card-table">
          <div class="card-header">
            All Apps
            <div
              class="btn btn-primary float-right"
              @click="isHidden = !isHidden"
              v-if="loggedInUser.role === adminRole"
            >
              Add App
            </div>
          </div>
          <div class="card-body">
            <APKTable />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import APKCreationForm from "@/components/APK/CreationForm";
import APKTable from "@/components/APK/Table";

export default {
  name: "APKComponent",
  components: { APKTable, APKCreationForm },
  data() {
    return {
      isHidden: false,
      adminRole: "ared_admin",
    };
  },
  computed: {
    ...mapGetters(["loggedInUser"]),
  },
};
</script>
<style></style>
