<template>
  <div>
    <div class="row col-12 page-head">
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb page-head-nav">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'dashboard' }">Home</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link-active :to="{ name: 'apps' }">Apps</router-link-active>
          </li>
        </ol>
      </nav>
    </div>
    <APKComponent/>
  </div>
</template>
<script>
import APKComponent from "@/components/APK/APKComponent";

export default {
  components: {APKComponent},
};
</script>
<style></style>
