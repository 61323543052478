<template>
  <table class="table table-striped table-hover table-fw-widget" id="table5">
    <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Created</th>
      <th></th>
      <th></th>
    </tr>
    </thead>
    <tbody v-if="apps">
    <tr v-for="app in apps" :key="app.id" class="odd gradeX">
      <td>{{ app.name }}</td>
      <td class="text capitalize">{{ app.apk_type }}</td>
      <td>
        {{ new Date(app.created).toLocaleString() }}
      </td>
      <td>
        <router-link :to="{ name: 'app-details', params: { id: app.id } }">
          <button class="btn btn-primary">View</button>
        </router-link>
      </td>
      <td>
        <button
            class="btn btn-sm btn-danger"
            data-toggle="modal"
            data-target="#apkModal"
            @click="modalInfo(app)"
        >
          Delete
        </button>
      </td>
      <td class="center"></td>
    </tr>
    <tr v-if="apps.length === 0">
      <td colspan="5">No apps found.</td>
    </tr>
    </tbody>
    <tbody v-else>
    <tr>
      <td colspan="5">Loading...</td>
    </tr>
    </tbody>
    <div
        id="apkModal"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="apkModalLabel"
        aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="apkModalLabel" class="modal-title">
              Delete APK
            </h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Are you sure you want to delete {{ selectedAPK.name }}?</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal">
              Dismiss
            </button>
            <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                @click="deleteAPK(selectedAPK.id)"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </table>
</template>

<script>
import axios from "axios";

export default {
  name: "APKTable",
  data() {
    return {
      apps: null,
      error: "",
      selectedAPK: "",
    };
  },
  mounted() {
    this.getAPKs();
  },
  methods: {
    getAPKs() {
      axios
          .get(`v1/apks/`)
          .then((response) => {
            if (response.status === 200) {
              this.apps = response.data;
            }
          })
          .catch((error) => {
            if (error.response) {
              this.$swal("Error", `${error.response.data.detail}`, "error");
            }
          });
    },
    deleteAPK(id) {
      axios.delete(`v1/apks/${id}/`).then((response) => {
        if (response.status === 204) {
          this.$swal("Success", "APK Successfully deleted", "success");
          this.getAPKs()
          // this.$forceUpdate();
        }
      });
    },
    modalInfo(product) {
      this.selectedAPK = product;
    },
  },
};
</script>
