<template>
  <main class="container my-5">
    <div class="row">
      <div class="col-md-12 mx-auto">
        <form @submit.prevent="submitAPK">
          <div class="form-group">
            <label for>App Name/Version</label>
            <input
              v-model="apk.name"
              type="text"
              class="form-control"
              placeholder="Enter App Name"
              required
            />
          </div>
          <div class="form-group pt-2">
            <label for="selectPlan">Type</label>
            <select
              v-model="apk.apk_type"
              class="form-control"
              id="appType"
              name="appType"
              required
            >
              <option value="">Select App Type</option>
              <option value="monitoring"> Monitoring</option>
              <option value="microfranchisees"> Microfranchisee</option>
            </select>
          </div>
          <div class="form-group pt-2">
            <label for="example-url-input">File URL</label>
            <input
              class="form-control"
              type="url"
              v-model="apk.file_url"
              placeholder="Enter Link to App Link"
              id="apkUrlInput"
              required
            />
          </div>

          <div class="d-flex justify-content-center pt-3">
            <button
              class="btn btn-space btn-primary"
              :disabled="submitting"
              type="submit"
            >
              <span
                v-if="submitting"
                class="spinner-border spinner-border-sm"
              ></span>
              {{ submit }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>
<script>
import axios from "axios";
import router from "@/router";

export default {
  name: "APKCreationForm",
  data() {
    return {
      apk: {
        name: "",
        file_url: "",
        apk_type: "",
      },
      submit: "Submit",
      submitting: false,
    };
  },
  methods: {
    async submitAPK() {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      const formData = new FormData();
      for (const data in this.apk) {
        formData.append(data, this.apk[data]);
      }
      try {
        this.submit = "Adding App";
        this.submitting == true;
        axios.post(`v1/apks/`, formData, config).then((response) => {
          if (response.status === 201) {
            router.push({
              name: "app-details",
              params: { id: response.data.id },
            });
            this.$swal(" ", "APK File Added Successfully", "success");
          }
        });
      } catch (e) {
        // this.$toast.error(`${this.apk.data}`)
        // eslint-disable-next-line no-console
        console.log(e);
      }
    },
  },
};
</script>
<style scoped></style>
